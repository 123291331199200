<template>
  <section id="list-patrocinadores">
    <b-card no-body class="mb-0">
      <div class="m-2">
        <add-new 
          v-if="isAddNewSidebarActive" 
          :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
          :category="category" 
          @refresh="refresh()" 
          @close="isAddNewUserSidebarActive = false"
        />
        <edit
          v-if="isEditSidebarActive"
          :is-edit-sidebar-active.sync="isEditSidebarActive"
          :data="rowData"
          :category="category"
          @refresh="refresh()"
          @close="isEditSidebarActive = false"
        />
        <b-row>
          <b-col cols="12" md="3" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Mostrar</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>Categorias</label>
          </b-col>
          <b-col cols="12" md="5">
            <div class="d-flex align-items-center justify-content-end">
                <b-form-input v-model="searchValue" class="d-inline-block mr-1" placeholder="Pesquisar..." />
              </div>
          </b-col>
          <b-col cols="12" md="4">
              <div class="d-flex align-items-center justify-content-end">
                <b-button variant="primary" @click="isAddNewSidebarActive = true" >
                  <span class="text-nowrap">Adicionar patrocinador</span>
                </b-button>
              </div>
          </b-col>
        </b-row>
      </div>
      <div class="overflow-auto">
        <b-table id="tablePatrocinadores" ref="tablePatrocinadores" hover striped :items="categorias" :fields="tableColumns" :filter="searchValue" primary-key="id" :total-rows="rows" :per-page="perPage" :sort-by="sortBy" :sort-desc="sortDesc" :current-page="currentPage" show-empty empty-text="Nenhuma informação encontrada" busy.sync="isBusy" empty-filtered-text="Nenhum registro filtrado">
          <template slot="empty">
            <div v-if="isBusy" class="text-center" >
              <b-spinner class="align-middle" />
            </div>
            <div v-else class="text-center">Nenhuma informação encontrada</div>
          </template>
          <template #cell(logo)="data">
            <img :src="getLogoUrl(data.item.logo)" style="max-height: 65px; min-height: 30px; max-width: 140px;">
          </template>
          <template #cell(actions)="data">
            <a @click="edit(data.item, category)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar </span>
            </a>
            ||
            <a @click="del(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Deletar</span>
            </a>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            </b-col>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="tablePatrocinadores" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18"/>
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </section>
</template>

<script>
  import { BCard, BRow, BCol, BTable, BFormInput, } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import { ref, onUnmounted } from '@vue/composition-api'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

  import AddNew from './AddNew.vue'
  import Edit from './Edit.vue'
  import axios from 'axios'

  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BTable,
      vSelect,
      BFormInput,
      AddNew,
      Edit,
    },
    setup() {
      const isAddNewSidebarActive = ref(false)
      const isEditSidebarActive = ref(false)
      return {
        isAddNewSidebarActive,
        isEditSidebarActive,
      }
    },
    data() {
      return {
        perPage: 10,
        perPageOptions: [10, 25, 50, 100, 200, 300, 400, 500],
        searchValue: '',
        rows: 0,
        tableColumns: [
          {
            key: 'logo',
            label: 'LOGO',
            sortable: true,
          },
          {
            key: 'title',
            label: 'TITLE',
            sortable: true,
          },
          {
            key: 'link',
            label: 'LINK',
            sortable: true,
          },
          {
            key: 'actions',
            label: 'Ações',
          },
        ],
        rowData: {},
        sortBy: 'id',
        sortDesc: true,
        currentPage: 1,
        isBusy: false,
        category: '',
        categorias: [
        ]
      }
    },
    watch: {
      isAddNewSidebarActive(value) {
        // O watch verifica a variável sempre que ela for alterada
        if (!value) {
          // Se o novo valor for falso (false), chamamos o outro método
          this.getListCategorias();
        }
      },
      isEditSidebarActive(value) {
        // O watch verifica a variável sempre que ela for alterada
        if (!value) {
          // Se o novo valor for falso (false), chamamos o outro método
          this.getListCategorias();
        }
      }
    },
    methods: {
      getLogoUrl(logo) {
        return `${process.env.VUE_APP_API_BASE}/storage/${logo}`
      },
      refresh(saved) {
        this.getListCategorias();
      },
      getListCategorias(){
        let queryParams = { 'category_id': this.$route.params.category}

        axios.get(`${process.env.VUE_APP_API}/patrocinadores`, {
          params: queryParams,
        })
          .then(response => {
            console.log(response.data)
            this.categorias = response.data.response.list[0].patrocinadores
            this.category = response.data.response.list[0].title
            console.log(this.category)

          })
          .catch(error => {
            console.log(error)
          })
      },
      edit(data) {
        this.rowData.id = data.id
        this.rowData.title = data.title
        this.rowData.link = data.link
        this.isEditSidebarActive = true
      },
      del(id) {
        this.$swal({
          title: 'Deletar categoria de patrocinador?',
          text: 'Você não será capaz de reverte essa ação!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3b65f0',
          cancelButtonColor: '#c1c1c1',
          cancelButtonText: 'Não',
          confirmButtonText: 'Sim, desejo deletar!',
          showLoaderOnConfirm: true,
          preConfirm: () => axios.post(`${process.env.VUE_APP_API}/patrocinadores/delete`, {'id': id})
              .then(response => {
                console.log(response.data)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Sucesso ao apagar!',
                    icon: 'InfoIcon',
                    variant: 'success',
                  }
                })
                this.$emit('close')
                this.$emit('onSaved', true)
                this.getListCategorias()
              })
              .catch(error => {
                console.log(error)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    icon: 'InfoIcon',
                    title: error.message,
                    variant: 'danger',
                  }
                })
              }),
          allowOutsideClick: () => !this.$swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) {
            this.$swal.fire(
              'Deletado!',
              'Patrocinador deletado com sucesso.',
              'success'
            )
          }
        })
      },
    },
    beforeMount() {
      this.getListCategorias()
    },
  }
</script>

<style lang="scss">

</style>